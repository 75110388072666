// Generated by IcedCoffeeScript 108.0.8
(function() {
  var AES, BlockCipher, G, Global, scrub_vec,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  BlockCipher = require('./algbase').BlockCipher;

  scrub_vec = require('./util').scrub_vec;

  Global = (function() {
    function Global() {
      var i;
      this.SBOX = [];
      this.INV_SBOX = [];
      this.SUB_MIX = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 4; i = ++_i) {
          _results.push([]);
        }
        return _results;
      })();
      this.INV_SUB_MIX = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 4; i = ++_i) {
          _results.push([]);
        }
        return _results;
      })();
      this.init();
      this.RCON = [0x00, 0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80, 0x1b, 0x36];
    }

    Global.prototype.init = function() {
      var d, i, sx, t, x, x2, x4, x8, xi, _i;
      d = (function() {
        var _i, _results;
        _results = [];
        for (i = _i = 0; _i < 256; i = ++_i) {
          if (i < 128) {
            _results.push(i << 1);
          } else {
            _results.push((i << 1) ^ 0x11b);
          }
        }
        return _results;
      })();
      x = 0;
      xi = 0;
      for (i = _i = 0; _i < 256; i = ++_i) {
        sx = xi ^ (xi << 1) ^ (xi << 2) ^ (xi << 3) ^ (xi << 4);
        sx = (sx >>> 8) ^ (sx & 0xff) ^ 0x63;
        this.SBOX[x] = sx;
        this.INV_SBOX[sx] = x;
        x2 = d[x];
        x4 = d[x2];
        x8 = d[x4];
        t = (d[sx] * 0x101) ^ (sx * 0x1010100);
        this.SUB_MIX[0][x] = (t << 24) | (t >>> 8);
        this.SUB_MIX[1][x] = (t << 16) | (t >>> 16);
        this.SUB_MIX[2][x] = (t << 8) | (t >>> 24);
        this.SUB_MIX[3][x] = t;
        t = (x8 * 0x1010101) ^ (x4 * 0x10001) ^ (x2 * 0x101) ^ (x * 0x1010100);
        this.INV_SUB_MIX[0][sx] = (t << 24) | (t >>> 8);
        this.INV_SUB_MIX[1][sx] = (t << 16) | (t >>> 16);
        this.INV_SUB_MIX[2][sx] = (t << 8) | (t >>> 24);
        this.INV_SUB_MIX[3][sx] = t;
        if (x === 0) {
          x = xi = 1;
        } else {
          x = x2 ^ d[d[d[x8 ^ x2]]];
          xi ^= d[d[xi]];
        }
      }
      return true;
    };

    return Global;

  })();

  G = new Global();

  AES = (function(_super) {
    __extends(AES, _super);

    AES.blockSize = 4 * 4;

    AES.prototype.blockSize = AES.blockSize;

    AES.keySize = 256 / 8;

    AES.prototype.keySize = AES.keySize;

    AES.ivSize = AES.blockSize;

    AES.prototype.ivSize = AES.ivSize;

    function AES(key) {
      this._key = key.clone();
      this._doReset();
    }

    AES.prototype._doReset = function() {
      var invKsRow, keySize, keyWords, ksRow, ksRows, t, _i, _j;
      keyWords = this._key.words;
      keySize = this._key.sigBytes / 4;
      this._nRounds = keySize + 6;
      ksRows = (this._nRounds + 1) * 4;
      this._keySchedule = [];
      for (ksRow = _i = 0; 0 <= ksRows ? _i < ksRows : _i > ksRows; ksRow = 0 <= ksRows ? ++_i : --_i) {
        this._keySchedule[ksRow] = ksRow < keySize ? keyWords[ksRow] : (t = this._keySchedule[ksRow - 1], (ksRow % keySize) === 0 ? (t = (t << 8) | (t >>> 24), t = (G.SBOX[t >>> 24] << 24) | (G.SBOX[(t >>> 16) & 0xff] << 16) | (G.SBOX[(t >>> 8) & 0xff] << 8) | G.SBOX[t & 0xff], t ^= G.RCON[(ksRow / keySize) | 0] << 24) : keySize > 6 && ksRow % keySize === 4 ? t = (G.SBOX[t >>> 24] << 24) | (G.SBOX[(t >>> 16) & 0xff] << 16) | (G.SBOX[(t >>> 8) & 0xff] << 8) | G.SBOX[t & 0xff] : void 0, this._keySchedule[ksRow - keySize] ^ t);
      }
      this._invKeySchedule = [];
      for (invKsRow = _j = 0; 0 <= ksRows ? _j < ksRows : _j > ksRows; invKsRow = 0 <= ksRows ? ++_j : --_j) {
        ksRow = ksRows - invKsRow;
        t = this._keySchedule[ksRow - (invKsRow % 4 ? 0 : 4)];
        this._invKeySchedule[invKsRow] = invKsRow < 4 || ksRow <= 4 ? t : G.INV_SUB_MIX[0][G.SBOX[t >>> 24]] ^ G.INV_SUB_MIX[1][G.SBOX[(t >>> 16) & 0xff]] ^ G.INV_SUB_MIX[2][G.SBOX[(t >>> 8) & 0xff]] ^ G.INV_SUB_MIX[3][G.SBOX[t & 0xff]];
      }
      return true;
    };

    AES.prototype.encryptBlock = function(M, offset) {
      if (offset == null) {
        offset = 0;
      }
      return this._doCryptBlock(M, offset, this._keySchedule, G.SUB_MIX, G.SBOX);
    };

    AES.prototype.decryptBlock = function(M, offset) {
      var _ref, _ref1;
      if (offset == null) {
        offset = 0;
      }
      _ref = [M[offset + 3], M[offset + 1]], M[offset + 1] = _ref[0], M[offset + 3] = _ref[1];
      this._doCryptBlock(M, offset, this._invKeySchedule, G.INV_SUB_MIX, G.INV_SBOX);
      return _ref1 = [M[offset + 3], M[offset + 1]], M[offset + 1] = _ref1[0], M[offset + 3] = _ref1[1], _ref1;
    };

    AES.prototype.scrub = function() {
      scrub_vec(this._keySchedule);
      scrub_vec(this._invKeySchedule);
      return this._key.scrub();
    };

    AES.prototype._doCryptBlock = function(M, offset, keySchedule, SUB_MIX, SBOX) {
      var ksRow, round, s0, s1, s2, s3, t0, t1, t2, t3, _i, _ref;
      s0 = M[offset] ^ keySchedule[0];
      s1 = M[offset + 1] ^ keySchedule[1];
      s2 = M[offset + 2] ^ keySchedule[2];
      s3 = M[offset + 3] ^ keySchedule[3];
      ksRow = 4;
      for (round = _i = 1, _ref = this._nRounds; 1 <= _ref ? _i < _ref : _i > _ref; round = 1 <= _ref ? ++_i : --_i) {
        t0 = SUB_MIX[0][s0 >>> 24] ^ SUB_MIX[1][(s1 >>> 16) & 0xff] ^ SUB_MIX[2][(s2 >>> 8) & 0xff] ^ SUB_MIX[3][s3 & 0xff] ^ keySchedule[ksRow++];
        t1 = SUB_MIX[0][s1 >>> 24] ^ SUB_MIX[1][(s2 >>> 16) & 0xff] ^ SUB_MIX[2][(s3 >>> 8) & 0xff] ^ SUB_MIX[3][s0 & 0xff] ^ keySchedule[ksRow++];
        t2 = SUB_MIX[0][s2 >>> 24] ^ SUB_MIX[1][(s3 >>> 16) & 0xff] ^ SUB_MIX[2][(s0 >>> 8) & 0xff] ^ SUB_MIX[3][s1 & 0xff] ^ keySchedule[ksRow++];
        t3 = SUB_MIX[0][s3 >>> 24] ^ SUB_MIX[1][(s0 >>> 16) & 0xff] ^ SUB_MIX[2][(s1 >>> 8) & 0xff] ^ SUB_MIX[3][s2 & 0xff] ^ keySchedule[ksRow++];
        s0 = t0;
        s1 = t1;
        s2 = t2;
        s3 = t3;
      }
      t0 = ((SBOX[s0 >>> 24] << 24) | (SBOX[(s1 >>> 16) & 0xff] << 16) | (SBOX[(s2 >>> 8) & 0xff] << 8) | SBOX[s3 & 0xff]) ^ keySchedule[ksRow++];
      t1 = ((SBOX[s1 >>> 24] << 24) | (SBOX[(s2 >>> 16) & 0xff] << 16) | (SBOX[(s3 >>> 8) & 0xff] << 8) | SBOX[s0 & 0xff]) ^ keySchedule[ksRow++];
      t2 = ((SBOX[s2 >>> 24] << 24) | (SBOX[(s3 >>> 16) & 0xff] << 16) | (SBOX[(s0 >>> 8) & 0xff] << 8) | SBOX[s1 & 0xff]) ^ keySchedule[ksRow++];
      t3 = ((SBOX[s3 >>> 24] << 24) | (SBOX[(s0 >>> 16) & 0xff] << 16) | (SBOX[(s1 >>> 8) & 0xff] << 8) | SBOX[s2 & 0xff]) ^ keySchedule[ksRow++];
      M[offset] = t0;
      M[offset + 1] = t1;
      M[offset + 2] = t2;
      return M[offset + 3] = t3;
    };

    return AES;

  })(BlockCipher);

  exports.AES = AES;

}).call(this);
