// Generated by IcedCoffeeScript 108.0.8
(function() {
  var Global, SHA384, SHA512, WordArray, X64WordArray, transform, _ref, _ref1,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  _ref = require('./wordarray'), X64WordArray = _ref.X64WordArray, WordArray = _ref.WordArray;

  _ref1 = require('./sha512'), SHA512 = _ref1.SHA512, Global = _ref1.Global;

  SHA384 = (function(_super) {
    __extends(SHA384, _super);

    function SHA384() {
      return SHA384.__super__.constructor.apply(this, arguments);
    }

    SHA384.output_size = 384 / 8;

    SHA384.prototype.output_size = SHA384.output_size;

    SHA384.prototype._doReset = function() {
      return this._hash = new X64WordArray(Global.convert([0xcbbb9d5d, 0xc1059ed8, 0x629a292a, 0x367cd507, 0x9159015a, 0x3070dd17, 0x152fecd8, 0xf70e5939, 0x67332667, 0xffc00b31, 0x8eb44a87, 0x68581511, 0xdb0c2e0d, 0x64f98fa7, 0x47b5481d, 0xbefa4fa4]));
    };

    SHA384.prototype._doFinalize = function() {
      var hash;
      hash = SHA384.__super__._doFinalize.call(this);
      hash.sigBytes -= 16;
      return hash;
    };

    SHA384.prototype.clone = function() {
      var out;
      out = new SHA384();
      this.copy_to(out);
      return out;
    };

    return SHA384;

  })(SHA512);

  transform = function(x) {
    var out;
    out = (new SHA384).finalize(x);
    x.scrub();
    return out;
  };

  exports.SHA384 = SHA384;

  exports.transform = transform;

}).call(this);
