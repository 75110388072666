// Generated by IcedCoffeeScript 108.0.8
(function() {
  var default_delay, iced, uint_max, __iced_k, __iced_k_noop;

  iced = require('iced-runtime');
  __iced_k = __iced_k_noop = function() {};

  uint_max = Math.pow(2, 32);

  exports.fixup_uint32 = function(x) {
    var ret, x_pos;
    ret = x > uint_max || x < 0 ? (x_pos = Math.abs(x) % uint_max, x < 0 ? uint_max - x_pos : x_pos) : x;
    return ret;
  };

  exports.scrub_buffer = function(b) {
    var i, n_full_words;
    n_full_words = b.length >> 2;
    i = 0;
    while (i < n_full_words) {
      b.writeUInt32LE(0, i);
      i += 4;
    }
    while (i < b.length) {
      b.writeUInt8(0, i);
      i++;
    }
    return false;
  };

  exports.copy_buffer = function(b) {
    var i, ret, _i, _ref;
    ret = new Buffer(b.length);
    for (i = _i = 0, _ref = b.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      ret.writeUInt8(b.readUInt8(i), i);
    }
    return ret;
  };

  exports.scrub_vec = function(v) {
    var i, _i, _ref;
    for (i = _i = 0, _ref = v.length; 0 <= _ref ? _i < _ref : _i > _ref; i = 0 <= _ref ? ++_i : --_i) {
      v[i] = 0;
    }
    return false;
  };

  exports.default_delay = default_delay = function(i, n, cb) {
    var ___iced_passed_deferral, __iced_deferrals, __iced_k;
    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    (function(_this) {
      return (function(__iced_k) {
        if (typeof setImmediate !== "undefined" && setImmediate !== null) {
          (function(__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/triplesec/src/util.iced"
            });
            setImmediate(__iced_deferrals.defer({
              lineno: 45
            }));
            __iced_deferrals._fulfill();
          })(__iced_k);
        } else {
          (function(__iced_k) {
            __iced_deferrals = new iced.Deferrals(__iced_k, {
              parent: ___iced_passed_deferral,
              filename: "/Users/max/src/keybase/triplesec/src/util.iced"
            });
            setTimeout(__iced_deferrals.defer({
              lineno: 47
            }), 1);
            __iced_deferrals._fulfill();
          })(__iced_k);
        }
      });
    })(this)((function(_this) {
      return function() {
        return cb();
      };
    })(this));
  };

  exports.buffer_cmp_ule = function(b1, b2) {
    var I, J, i, j, x, y;
    i = j = 0;
    I = b1.length;
    J = b2.length;
    while (i < I && b1.readUInt8(i) === 0) {
      i++;
    }
    while (j < J && b2.readUInt8(j) === 0) {
      j++;
    }
    if ((I - i) > (J - j)) {
      return 1;
    } else if ((J - j) > (I - i)) {
      return -1;
    }
    while (i < I) {
      if ((x = b1.readUInt8(i)) < (y = b2.readUInt8(j))) {
        return -1;
      } else if (y < x) {
        return 1;
      }
      i++;
      j++;
    }
    return 0;
  };

  exports.bulk = function(n_input_bytes, _arg, _arg1) {
    var call_ph, cb, default_n, delay, finalize, i, left, n, n_words, progress_hook, ret, total_words, update, what, ___iced_passed_deferral, __iced_deferrals, __iced_k;
    __iced_k = __iced_k_noop;
    ___iced_passed_deferral = iced.findDeferral(arguments);
    update = _arg.update, finalize = _arg.finalize, default_n = _arg.default_n;
    delay = _arg1.delay, n = _arg1.n, cb = _arg1.cb, what = _arg1.what, progress_hook = _arg1.progress_hook;
    i = 0;
    left = 0;
    total_words = Math.ceil(n_input_bytes / 4);
    delay || (delay = default_delay);
    n || (n = default_n);
    call_ph = function(i) {
      return typeof progress_hook === "function" ? progress_hook({
        what: what,
        i: i,
        total: total_words
      }) : void 0;
    };
    call_ph(0);
    (function(_this) {
      return (function(__iced_k) {
        var _while;
        _while = function(__iced_k) {
          var _break, _continue, _next;
          _break = __iced_k;
          _continue = function() {
            return iced.trampoline(function() {
              return _while(__iced_k);
            });
          };
          _next = _continue;
          if (!((left = total_words - i) > 0)) {
            return _break();
          } else {
            n_words = Math.min(n, left);
            update(i, i + n_words);
            call_ph(i);
            (function(__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/keybase/triplesec/src/util.iced",
                funcname: "bulk"
              });
              delay(i, total_words, __iced_deferrals.defer({
                lineno: 105
              }));
              __iced_deferrals._fulfill();
            })(function() {
              return _next(i += n_words);
            });
          }
        };
        _while(__iced_k);
      });
    })(this)((function(_this) {
      return function() {
        call_ph(total_words);
        ret = finalize();
        return cb(ret);
      };
    })(this));
  };

}).call(this);
