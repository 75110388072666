// Generated by IcedCoffeeScript 108.0.8
(function() {
  var Hasher, SHA1, W, WordArray, transform,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  WordArray = require('./wordarray').WordArray;

  Hasher = require('./algbase').Hasher;

  W = [];

  SHA1 = (function(_super) {
    __extends(SHA1, _super);

    function SHA1() {
      return SHA1.__super__.constructor.apply(this, arguments);
    }

    SHA1.blockSize = 512 / 32;

    SHA1.prototype.blockSize = SHA1.blockSize;

    SHA1.output_size = 20;

    SHA1.prototype.output_size = SHA1.output_size;

    SHA1.prototype._doReset = function() {
      return this._hash = new WordArray([0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476, 0xc3d2e1f0]);
    };

    SHA1.prototype._doProcessBlock = function(M, offset) {
      var H, a, b, c, d, e, i, n, t, _i;
      H = this._hash.words;
      a = H[0];
      b = H[1];
      c = H[2];
      d = H[3];
      e = H[4];
      for (i = _i = 0; _i < 80; i = ++_i) {
        if (i < 16) {
          W[i] = M[offset + i] | 0;
        } else {
          n = W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16];
          W[i] = (n << 1) | (n >>> 31);
        }
        t = ((a << 5) | (a >>> 27)) + e + W[i];
        if (i < 20) {
          t += ((b & c) | (~b & d)) + 0x5a827999;
        } else if (i < 40) {
          t += (b ^ c ^ d) + 0x6ed9eba1;
        } else if (i < 60) {
          t += ((b & c) | (b & d) | (c & d)) - 0x70e44324;
        } else {
          t += (b ^ c ^ d) - 0x359d3e2a;
        }
        e = d;
        d = c;
        c = (b << 30) | (b >>> 2);
        b = a;
        a = t;
      }
      H[0] = (H[0] + a) | 0;
      H[1] = (H[1] + b) | 0;
      H[2] = (H[2] + c) | 0;
      H[3] = (H[3] + d) | 0;
      return H[4] = (H[4] + e) | 0;
    };

    SHA1.prototype._doFinalize = function() {
      var data, dataWords, nBitsLeft, nBitsTotal;
      data = this._data;
      dataWords = data.words;
      nBitsTotal = this._nDataBytes * 8;
      nBitsLeft = data.sigBytes * 8;
      dataWords[nBitsLeft >>> 5] |= 0x80 << (24 - nBitsLeft % 32);
      dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 14] = Math.floor(nBitsTotal / 0x100000000);
      dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 15] = nBitsTotal;
      data.sigBytes = dataWords.length * 4;
      this._process();
      return this._hash;
    };

    SHA1.prototype.copy_to = function(obj) {
      SHA1.__super__.copy_to.call(this, obj);
      return obj._hash = this._hash.clone();
    };

    SHA1.prototype.clone = function() {
      var out;
      out = new SHA1();
      this.copy_to(out);
      return out;
    };

    return SHA1;

  })(Hasher);

  transform = transform = function(x) {
    var out;
    out = (new SHA1).finalize(x);
    x.scrub();
    return out;
  };

  exports.SHA1 = SHA1;

  exports.transform = transform;

}).call(this);
