// Generated by IcedCoffeeScript 108.0.11
(function() {
  var Lock, NamedLock, SingleFlightTable, SingleFlighter, Table, iced, __iced_k, __iced_k_noop,
    __hasProp = {}.hasOwnProperty,
    __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

  iced = require('iced-runtime');
  __iced_k = __iced_k_noop = function() {};

  exports.Lock = Lock = (function() {
    function Lock() {
      this._open = true;
      this._waiters = [];
    }

    Lock.prototype.acquire = function(cb) {
      if (this._open) {
        this._open = false;
        return cb();
      } else {
        return this._waiters.push(cb);
      }
    };

    Lock.prototype.release = function() {
      var w;
      if (this._waiters.length) {
        w = this._waiters.shift();
        return w();
      } else {
        return this._open = true;
      }
    };

    Lock.prototype.open = function() {
      return this._open;
    };

    return Lock;

  })();

  NamedLock = (function(_super) {
    __extends(NamedLock, _super);

    function NamedLock(tab, name) {
      this.tab = tab;
      this.name = name;
      NamedLock.__super__.constructor.call(this);
      this.refs = 0;
    }

    NamedLock.prototype.incref = function() {
      return ++this.refs;
    };

    NamedLock.prototype.decref = function() {
      return --this.refs;
    };

    NamedLock.prototype.release = function() {
      NamedLock.__super__.release.call(this);
      if (this.decref() === 0) {
        return delete this.tab.locks[this.name];
      }
    };

    return NamedLock;

  })(Lock);

  exports.Table = Table = (function() {
    function Table() {
      this.locks = {};
    }

    Table.prototype.create = function(name) {
      var l;
      l = new NamedLock(this, name);
      return this.locks[name] = l;
    };

    Table.prototype.acquire = function(name, cb, wait) {
      var l, was_open, ___iced_passed_deferral, __iced_deferrals, __iced_k;
      __iced_k = __iced_k_noop;
      ___iced_passed_deferral = iced.findDeferral(arguments);
      l = this.locks[name] || this.create(name);
      was_open = l._open;
      l.incref();
      (function(_this) {
        return (function(__iced_k) {
          if (wait || l._open) {
            (function(__iced_k) {
              __iced_deferrals = new iced.Deferrals(__iced_k, {
                parent: ___iced_passed_deferral,
                filename: "/Users/max/src/iced/iced-lock/index.iced",
                funcname: "Table.acquire"
              });
              l.acquire(__iced_deferrals.defer({
                lineno: 69
              }));
              __iced_deferrals._fulfill();
            })(__iced_k);
          } else {
            return __iced_k(l = null);
          }
        });
      })(this)((function(_this) {
        return function() {
          return cb(l, was_open);
        };
      })(this));
    };

    Table.prototype.lookup = function(name) {
      return this.locks[name];
    };

    return Table;

  })();

  SingleFlighter = (function() {
    function SingleFlighter(_arg) {
      this.table = _arg.table, this.key = _arg.key;
      this.seqid = null;
      this.waiter = null;
      this.open = true;
      this.refs = 0;
    }

    SingleFlighter.prototype._incref = function() {
      return ++this.refs;
    };

    SingleFlighter.prototype._decref = function() {
      if (--this.refs === 0) {
        return this.table._remove({
          key: this.key
        });
      }
    };

    SingleFlighter.prototype._enter = function(_arg, cb) {
      var seqid, tmp;
      seqid = _arg.seqid;
      if (this.open) {
        this.open = false;
        this.seqid = seqid;
        return cb(null, this);
      } else if (this.waiter != null) {
        if (seqid > this.waiter.seqid) {
          tmp = this.waiter;
          this.waiter = {
            cb: cb,
            seqid: seqid
          };
          tmp.cb(new Error("our seqid=" + tmp.seqid + " was preempted by " + seqid));
        } else {
          cb(new Error("our seqid=" + seqid + " is too stale (since " + this.waiter.seqid + " is ahead of us)"));
        }
        return this._decref();
      } else if (seqid > this.seqid) {
        return this.waiter = {
          seqid: seqid,
          cb: cb
        };
      } else {
        cb(new Error("our seqid=" + seqid + " is too stale (since " + this.seqid + " is already in flight)"));
        return this._decref();
      }
    };

    SingleFlighter.prototype.release = function() {
      var cb, _ref;
      if (this.waiter != null) {
        _ref = this.waiter, this.seqid = _ref.seqid, cb = _ref.cb;
        this.waiter = null;
        cb(null, this);
      } else {
        this.open = true;
        this.seqid = null;
      }
      return this._decref();
    };

    return SingleFlighter;

  })();

  exports.SingleFlightTable = SingleFlightTable = (function() {
    function SingleFlightTable() {
      this._jobs = {};
    }

    SingleFlightTable.prototype._create = function(_arg) {
      var key;
      key = _arg.key;
      return this._jobs[key] = new SingleFlighter({
        table: this,
        key: key
      });
    };

    SingleFlightTable.prototype._remove = function(_arg) {
      var key;
      key = _arg.key;
      return delete this._jobs[key];
    };

    SingleFlightTable.prototype.enter = function(_arg, cb) {
      var key, s, seqid;
      seqid = _arg.seqid, key = _arg.key;
      s = this._jobs[key] || this._create({
        key: key
      });
      s._incref();
      return s._enter({
        seqid: seqid
      }, cb);
    };

    return SingleFlightTable;

  })();

}).call(this);
