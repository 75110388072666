'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function () {
  return {
    x: window.pageXOffset,
    y: window.pageYOffset
  };
};